import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { /*GatsbyImage,*/ getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import OrganizationLink from "../components/OrganizationLink"
import Footer from "../components/Footer"
import { Row, Col, Table, Button } from "antd"
import { FileTextOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"

const IntroContainer = styled.div`
  margin: 60px 0;
  .introWrapper {
    font-family: "Kanit", sans-serif;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0;
  }
`
const FullwidthContainer = styled.div`
  margin: 0;
  font-family: "Kanit", sans-serif;
  .gb-container {
    padding-right: 15px;
  }
  .title {
    font-weight: 400;
    font-size: 38px;
    color: #181818;
  }
  .content {
    font-weight: 400;
    font-size: 18px;
    color: #646464;
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin: 60px 0 40px 0;
    .gb-container {
      padding-right: 15%;
    }
  }
`
const TableContainer = styled.div`
  margin: 60px 0 90px 0;
  font-family: "Kanit", sans-serif;
  .title {
    font-weight: 400;
    font-size: 24px;
    color: #181818;
    margin-bottom: 30px;
  }
  .ant-table-content {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
  }
  table {
    tr.ant-table-row {
      .ant-table-cell {
        font-weight: 400;
        font-size: 18px;
        color: #181818;
        &:first-of-type {
          font-weight: 700;
          color: #7497b2;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .ant-table-content {
      overflow-x: auto;
    }
  }
`
/*
const AwardContainer = styled.div`
  margin: -30px 0 80px 0;
  font-family: "Kanit", sans-serif;
  .awardWrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    .awardTop {
      margin-bottom: 0;
      .title {
        font-weight: 400;
        font-size: 38px;
        color: #181818;
      }
      .content {
        font-weight: 400;
        font-size: 18px;
        color: #646464;
      }
    }
    .awardBottom {
      display: none;
      min-width: auto;
      padding: 30px 45px;
      background: #ffffff;
      box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      z-index: 1;
      h4.title {
        font-weight: 700;
        font-size: 24px;
        color: #181818;
        margin-bottom: 25px;
      }
      .contentWrapper {
        position: relative;
        padding-left: 50px;
        .line {
          position: absolute;
          top: 10px;
          left: 0;
          width: 1px;
          height: 109px;
          border: 1px solid #7497b2;
        }
        p {
          font-weight: 400;
          font-size: 18px;
          color: #646464;
        }
        ul.list {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            color: #646464;
            margin-bottom: 10px;
            .icon {
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              margin-right: 10px;
              background-color: transparent;
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url("../../assets/images/icon-check.svg");
            }
          }
        }
      }
    }
  }
  .awardImage {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    img {
      border-radius: 10px;
    }
    .awardBottom {
      display: block;
      min-width: auto;
      padding: 30px 25px;
      margin-top: -40px;
      background: #ffffff;
      box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      z-index: 1;
      h4.title {
        font-weight: 700;
        font-size: 24px;
        color: #181818;
        margin-bottom: 15px;
      }
      .contentWrapper {
        position: relative;
        padding-left: 25px;
        .line {
          position: absolute;
          top: 10px;
          left: 0;
          width: 1px;
          height: 109px;
          border: 1px solid #7497b2;
        }
        p {
          font-weight: 400;
          font-size: 18px;
          color: #646464;
        }
        ul.list {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            color: #646464;
            margin-bottom: 10px;
            .icon {
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              margin-right: 10px;
              background-color: transparent;
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url("../../assets/images/icon-check.svg");
            }
          }
        }
      }
    }
  }
  

  @media only screen and (min-width: 576px) {
    margin: -60px 0 100px 0;
    .awardImage {
      .awardBottom {
        padding: 30px 45px;
        h4.title {
          margin-bottom: 25px;
        }
        .contentWrapper {
          padding-left: 50px;
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    margin: 80px 0 100px 0;
    .awardWrapper {
      .awardTop {
        margin-bottom: 50px;
      }
      .awardBottom {
        display: block;
        min-width: 150%;
        padding: 30px 45px;
      }
    }
    .awardImage {
      .awardBottom {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .awardWrapper {
      .awardBottom {
        min-width: 135%;
      }
    }
  }
`
*/
const columns = [
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
    width: 80,
  },
  {
    title: "Text",
    dataIndex: "text",
    key: "text",
    width: 420,
  },
  {
    title: "File",
    dataIndex: "file",
    key: "file",
    /*align: "right",
    fixed: "right",*/
    width: 40,
    render: (text, record) => {
      return (
        <Button
          type="link"
          size="middle"
          icon={<FileTextOutlined style={{ color: "#7497b2" }} />}
          href={record.file}
          target="_blank"
        />
      )
    },
  },
]

const StandardCertificationPage = ({ data, intl }) => {
  const [dataSource, setDataSource] = useState([])

  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, certificationLicense } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    standardTitle,
    standardContent,
    standardTitleTable,
    /*awardTitle,
    awardText,
    awardContent,
    awardImage,*/
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  useEffect(() => {
    if (certificationLicense) {
      const certifications = certificationLicense.edges.map((item, index) => {
        return {
          key: index,
          year: item.node.year,
          text: item.node.text,
          file: item.node.file.file.url,
        }
      })
      if (certifications) {
        setDataSource(certifications)
      }
    }
  }, [certificationLicense])

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu3Sub3" })}
        secondLink="/about-us/"
        secondText={intl.formatMessage({ id: "rootMenu3" })}
      />
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{intl.formatMessage({ id: "rootMenu3" })}</span>
                <h2>{intl.formatMessage({ id: "rootMenu3Sub3" })}</h2>
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <FullwidthContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h3
                className="title"
                dangerouslySetInnerHTML={{
                  __html: standardTitle,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: standardContent.standardContent,
                }}
              />
            </Col>
          </Row>
        </div>
      </FullwidthContainer>
      {dataSource ? (
        <TableContainer>
          <div className="gb-container">
            <Row>
              <Col span={24}>
                <h4
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: standardTitleTable,
                  }}
                />
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  showHeader={false}
                  /*scroll={{ x: 768 }}*/
                />
              </Col>
            </Row>
          </div>
        </TableContainer>
      ) : null}
      <OrganizationLink />
      {/*<AwardContainer>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={11} xxl={10}>
              <div className="awardWrapper">
                <div className="awardTop">
                  <h3
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: awardTitle,
                    }}
                  />
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: awardText.awardText,
                    }}
                  />
                </div>
                <div
                  className="awardBottom"
                  dangerouslySetInnerHTML={{
                    __html: awardContent.awardContent,
                  }}
                />
              </div>
            </Col>
            <Col xs={24} lg={13} xxl={14}>
              <div className="awardImage">
                <GatsbyImage image={getAwardImage} alt="" />
                <div
                  className="awardBottom"
                  dangerouslySetInnerHTML={{
                    __html: awardContent.awardContent,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </AwardContainer>
      */}
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query StandardCertificationPageQuery($locale: String) {
    pageFields: allContentfulStandardCertificationPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          standardTitle
          standardContent {
            standardContent
          }
          standardTitleTable
          awardTitle
          awardText {
            awardText
          }
          awardContent {
            awardContent
          }
          awardImage {
            gatsbyImageData(width: 915, placeholder: BLURRED)
          }
        }
      }
    }
    certificationLicense: allContentfulCertificationLicense(
      filter: { node_locale: { eq: $locale } }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          year
          text
          file {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default injectIntl(StandardCertificationPage)
